header{
  position: fixed;
  top:0; left: 0; right:0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background:#fff;
  padding:2rem 9%;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
}
header .left{
  display: flex;
  justify-content: space-between;
}

header .left div{
 margin-right:60px;
 text-align: center;
 vertical-align: middle;
 font-size: 2rem;
 cursor: pointer;
 display: flex;
 align-items: center;
}

header .left div:hover{
 transform: scale(1.2);
}
header .left div img{
  height: 20px;
 margin-right: 2px;
 
 }

header .logo{
  font-size: 2.5rem;
  font-weight: bolder;
  color:#666;
  transform: scale(1.2);
}
header .logo:hover{
  transform: scale(1.3);
}

header .logo i{
  padding-right: .5rem;
  color:var(--red);
}

header .navbar a{
  font-size: 2rem;
  margin-left: 2rem;
  color:#666;
}

header .navbar a:hover{
  color:var(--red);
}

#menu-bar{
  font-size: 3rem;
  cursor: pointer;
  color:#666;
  border:.1rem solid #666;
  border-radius: .3rem;
  padding:.5rem 1.5rem;
  display: none;
}







@media(max-width:768px){

  #menu-bar{
    display: initial;
  }

  header .navbar{
    position: absolute;
    top:100%; left: 0; right:0;
    background: #f7f7f7;
    border-top: .1rem solid rgba(0,0,0,.1);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  header .navbar.active{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  header .navbar a{
    margin: 1.5rem;
    padding:1.5rem;
    display: block;
    border:.2rem solid rgba(0,0,0,.1);
    border-left:.5rem solid var(--red);
    background:#fff;
  }

}

@media(max-width:450px){

  html{
    font-size: 50%;
  }

  .order .row form .inputBox input{
    width:100%;
  }

}