@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap);
:root{
  --red:#ff3838;
}

*{
  font-family: 'Nunito', sans-serif;
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none;
  text-decoration: none;
  text-transform: capitalize;
  transition:all .2s linear;
}

*::selection{
  background:#ff3838;
  background:var(--red);
  color:#fff;
}

html{
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: 6rem;
}

body{
  background:#f7f7f7;
}

section{
  padding:2rem 9%;
}

.heading{
  text-align: center;
  font-size: 3.5rem;
  padding:1rem;
  color:#666;
}

.heading span{
  color:#ff3838;
  color:var(--red);
}

.btn{
  display: inline-block;
  padding:.8rem 3rem;
  border:.2rem solid #ff3838;
  border:.2rem solid var(--red);
  color:#ff3838;
  color:var(--red);
  cursor: pointer;
  font-size: 3rem;
  border-radius: .5rem;
  position: relative;
  overflow: hidden;
  z-index: 0;
  margin-top: 1rem;
}

.btn::before{
  content: '';
  position: absolute;
  top:0; right: 0;
  width:0%;
  height:100%;
  background:#ff3838;
  background:var(--red);
  transition: .3s linear;
  z-index: -1;
}

.btn:hover::before{
  width:100%;
  left: 0;
}

.btn:hover{
  color:#fff;
}
@media(max-width:450px){

  html{
    font-size: 50%;
  }

  .order .row form .inputBox input{
    width:100%;
  }

}

header{
  position: fixed;
  top:0; left: 0; right:0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background:#fff;
  padding:2rem 9%;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
}
header .left{
  display: flex;
  justify-content: space-between;
}

header .left div{
 margin-right:60px;
 text-align: center;
 vertical-align: middle;
 font-size: 2rem;
 cursor: pointer;
 display: flex;
 align-items: center;
}

header .left div:hover{
 transform: scale(1.2);
}
header .left div img{
  height: 20px;
 margin-right: 2px;
 
 }

header .logo{
  font-size: 2.5rem;
  font-weight: bolder;
  color:#666;
  transform: scale(1.2);
}
header .logo:hover{
  transform: scale(1.3);
}

header .logo i{
  padding-right: .5rem;
  color:var(--red);
}

header .navbar a{
  font-size: 2rem;
  margin-left: 2rem;
  color:#666;
}

header .navbar a:hover{
  color:var(--red);
}

#menu-bar{
  font-size: 3rem;
  cursor: pointer;
  color:#666;
  border:.1rem solid #666;
  border-radius: .3rem;
  padding:.5rem 1.5rem;
  display: none;
}







@media(max-width:768px){

  #menu-bar{
    display: inline;
    display: initial;
  }

  header .navbar{
    position: absolute;
    top:100%; left: 0; right:0;
    background: #f7f7f7;
    border-top: .1rem solid rgba(0,0,0,.1);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
            clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }

  header .navbar.active{
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  header .navbar a{
    margin: 1.5rem;
    padding:1.5rem;
    display: block;
    border:.2rem solid rgba(0,0,0,.1);
    border-left:.5rem solid var(--red);
    background:#fff;
  }

}

@media(max-width:450px){

  html{
    font-size: 50%;
  }

  .order .row form .inputBox input{
    width:100%;
  }

}

.home{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
    min-height: 100vh;
    align-items: center;
    background:url(/static/media/home-bg.0194505c.jpg) no-repeat;
    background-size: cover;
    background-position: center;
  }
  
  .home .content{
    flex:1 1 40rem;
    padding-top: 6.5rem;
  
  }
  
  .home .image{
    flex:1 1 40rem;
  }
  
  .home .image img{
    width:100%;
    padding:1rem;
    animation:float 3s linear infinite;
  }
  
@keyframes float{
    0%, 100%{
      transform: translateY(0rem);
    }
    50%{
      transform: translateY(3rem);
    }
  }
  
  .home .content h3{
    font-size: 7rem;
    color:#333;
  }
  
  .home .content p{
    font-size: 2rem;
    color:#666;
    padding:1rem 0;
  }
.box{
    flex:1 1 30rem;
    position: relative;
    overflow: hidden;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border:.1rem solid rgba(0,0,0,.3);
    cursor: pointer;
    border-radius: .5rem;
}

.box .image{
    height:100%;
    width:100%;
    object-fit: cover;
    position: absolute;
    top:-100%; left:0;
  }



  .box .content{
    text-align: center;
    background:#fff;
    padding:2rem;
  }


  .box .content img{
    margin:1.5rem 0;
  }

  
  .box .content h3{
    font-size: 2.5rem;
    color:#333;
  }


  .box .content p{
    font-size: 1.6rem;
    color:#666;
    padding:1rem 0;
  }

  .box:hover .image{
    top:0;
  }

  box:hover .content{
    transform: translateY(100%);
  }
.speciality .box-container{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
  }

 .CardPrice .box{
  padding:2rem;
  background:#fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
  border:.1rem solid rgba(0,0,0,.3);
  border-radius: .5rem;
  text-align: center;
  flex:1 1 30rem;
  position: relative;
}

 .CardPrice .box img{
  height:25rem;
  object-fit: cover;
  width:100%;
  border-radius: .5rem;
}

 .CardPrice .box .price{
  position: absolute;
  top:3rem; left:3rem;
  background:var(--red);
  color:#fff;
  font-size: 2rem;
  padding:.5rem 1rem;
  border-radius: .5rem;
}

 .CardPrice .box h3{
  color:#333;
  font-size: 2.5rem;
  padding-top: 1rem;
}

 .CardPrice .box .stars i{
  color:gold;
  font-size: 1.7rem;
  padding:1rem .1rem;
}

.popular .CardPrice{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
  }
  

.steps{
  display: flex;
  flex-wrap: wrap;
  grid-gap:1.5rem;
  gap:1.5rem;
  padding:1rem;
}

.box-steps{
  flex:1 1 25rem;
  padding:1rem;
  text-align: center;
}
.steps-img{
  border-radius: 50%;
  border:1rem solid #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
}
.steps-h3{
  font-size: 3rem;
  color:#333;
  padding:1rem;
}



.GalleryCard{
  height:25rem;
  flex:1 1 30rem;
  border:1rem solid #fff;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
  border-radius: .5rem;
  position: relative;
  overflow: hidden;
}

.GalleryCard img{
  height:100%;
  width:100%;
  object-fit: cover;
}

.GalleryCard .content{
  position: absolute;
  top:-105%; left:0;
  height: 100%;
  width:100%;
  background:rgba(255,255,255,.9);
  padding:2rem;
  padding-top: 5rem;
  text-align: center;
}

.GalleryCard .content h3{
  font-size: 2.5rem;
  color:#333;
}

.GalleryCard .content p{
  font-size: 1.5rem;
  color:#666;
  padding:1rem 0;
}

.GalleryCard:hover .content{
  top:5;
}


.GalleryCard:hover .btn{
  font-size: 2.1rem;
}
.gallery .box-container{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
  }
.review .comments{
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
  }

.comment{
    text-align: center;
    padding:2rem;
    border:1rem solid #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.3);
    border-radius: .5rem;
    flex:1 1 30rem;
    background:#333;
    margin-top: 6rem;
  }
  
  .comment img{
    height: 12rem;
    width:12rem;
    border-radius: 50%;
    border:1rem solid #fff;
    margin-top: -8rem;
    object-fit: cover;
  }
  
  .comment h3{
    font-size: 2.5rem;
    color:#fff;
    padding:.5rem 0;
  }
  
  .comment .stars i{
    font-size: 2rem;
    color:var(--red);
    padding:.5rem 0;
  }
  
  .comment p{
    font-size: 1.5rem;
    color:#eee;
    padding:1rem 0;
  }
  

.order .row{
    padding:2rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    background:#fff;
    display: flex;
    flex-wrap: wrap;
    grid-gap:1.5rem;
    gap:1.5rem;
    border-radius: .5rem;
  }
  
  .order .row .image{
    flex:1 1 30rem;
  }
  
  .order .row .image img{
    height: 100%;
    width:100%;
    object-fit: cover;
    border-radius: .5rem;
  }
  
  .order .row form{
    flex:1 1 50rem;
    padding:1rem;
  }
  
  .order .row form .inputBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .order .row form .inputBox input, .order .row form textarea{
    padding:1rem;
    margin:1rem 0;
    font-size: 1.7rem;
    color:#333;
    text-transform: none;
    border:.1rem solid rgba(0,0,0,.3);
    border-radius: .5rem;
    width:49%;
  }
  
  .order .row form textarea{
    width:100%;
    resize: none;
    height:15rem;
  }
  
  .order .row form .btn{
    background:none;
  }
  
  .order .row form .btn:hover{
    background:var(--red);
  }

.footer{
    background:#000;
    text-align: center;
    height: 100px;
  }
  
  .footer .share{
    display: flex;
    grid-gap:1.5rem;
    gap:1.5rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  

  .wrapper .button{
    
    display: inline-block;
    height: 60px;
    width: 60px;
    float: left;
    margin: 0 5px;
    overflow: hidden;
    background: #fff;
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
    transition: all 0.3s ease-out;
  }
  .wrapper .button:hover{
    width: 200px;
  }
  .wrapper .button .icon{
    display: inline-block;
    height: 60px;
    width: 60px;
    text-align: center;
    border-radius: 50px;
    box-sizing: border-box;
    line-height: 60px;
    transition: all 0.3s ease-out;
  }
  .wrapper .button:nth-child(1):hover .icon{
    background: #4267B2;
  }
  .wrapper .button:nth-child(2):hover .icon{
    background: #1DA1F2;
  }
  .wrapper .button:nth-child(3):hover .icon{
    background: #E1306C;
  }
  .wrapper .button:nth-child(4):hover .icon{
    background: #0072b1 ;
  }
  .wrapper .button:nth-child(5):hover .icon{
    background: #ff0000;
  }
  .wrapper .button .icon i{
    font-size: 25px;
    line-height: 60px;
    transition: all 0.3s ease-out;
  }
  .wrapper .button:hover .icon i{
    color: #fff;
  }
  .wrapper .button span{
    font-size: 20px;
    font-weight: 500;
    line-height: 60px;
    margin-left: 10px;
    transition: all 0.3s ease-out;
  }
  .wrapper .button:nth-child(1) span{
    color: #4267B2;
  }
  .wrapper .button:nth-child(2) span{
    color: #1DA1F2;
  }
  .wrapper .button:nth-child(3) span{
    color: #E1306C;
  }
  .wrapper .button:nth-child(4) span{
    color: #333;
  }
  .wrapper .button:nth-child(5) span{
    color: #ff0000;
  }

