
.comment{
    text-align: center;
    padding:2rem;
    border:1rem solid #fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.3);
    border-radius: .5rem;
    flex:1 1 30rem;
    background:#333;
    margin-top: 6rem;
  }
  
  .comment img{
    height: 12rem;
    width:12rem;
    border-radius: 50%;
    border:1rem solid #fff;
    margin-top: -8rem;
    object-fit: cover;
  }
  
  .comment h3{
    font-size: 2.5rem;
    color:#fff;
    padding:.5rem 0;
  }
  
  .comment .stars i{
    font-size: 2rem;
    color:var(--red);
    padding:.5rem 0;
  }
  
  .comment p{
    font-size: 1.5rem;
    color:#eee;
    padding:1rem 0;
  }
  